<template>
  <div>
    <gallery :images="propsFile" :index="index" @close="index = null"></gallery>
  </div>
</template>
<script>
import VueGallery from "vue-gallery";

export default {
  components: {
    gallery: VueGallery,
  },
  data() {
    return {
      fileList: [],
      propsFile: [],
      index: null,
    };
  },
  props: {
    images: {
      default() {
        return [
          {
            title: "Fruits",
            type: "video/mp4",
            href: "http://test.asset.momoda.tech/upload/video/6d382f365508f82efd3b2835e1d3b238.mp4",
            poster:
              "http://test.asset.momoda.tech/upload/video/6d382f365508f82efd3b2835e1d3b238.mp4?spm=a2c4g.11186623.2.1.yjOb8V&x-oss-process=video/snapshot,t_7000,f_jpg,w_800,h_600,m_fast",
          },
          {
            title: "Banana",
            type: "image/jpeg",
            href: "http://test.asset.momoda.tech/upload/img/f7b1903c82c5a5cda40d2931cb5097f4.jpg",
            thumbnail:
              "http://test.asset.momoda.tech/upload/img/f7b1903c82c5a5cda40d2931cb5097f4.jpg",
          },
        ];
      },
    },
  },
  watch: {
    images: {
      deep: true,
      handler: function () {
        this.fileList = this.images;
        for (let i of this.fileList) {
          if (i.type == "video/mp4") {
            i.poster =
              i.href +
              "?spm=a2c4g.11186623.2.1.yjOb8V&x-oss-process=video/snapshot,t_0,f_jpg,w_0,h_0,m_fast,ar_auto";
          } else if (i.type == "image/jpeg") {
            i.thumbnail = i.href;
          }
        }
        this.propsFile = this.fileList;
        this.index = 0;
      },
    },
  },
};
</script>
