






















































































































































































































import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {getPageAPIs, Util} from "@/common/util";
import GoodsListAPIClass from "@/common/api/goodsList";
import { CommonApi } from "@/common/api/common";
import gallery from "@/components/gallery.vue";
export const pageApiList = getPageAPIs(GoodsListAPIClass);
const defaultForms = {
  name: '',
  price: '',
  category_id: '',
  bar_code: '',
  inventory: 1,
  inventory_warn_num: 1,
  wholesale_price: 0,
  images:[]
}

function createPicItem(id: number, url: string) {
  const item = {
    id,
    url,
    href: url,
    type: "image/png",
    types: "1",
  };

  return item;
}

@Component({
  components: {
    gallery,
  },
})
export default class UserList extends Vue {
  private buyAddressApi: any = new GoodsListAPIClass();
  private sellShow: boolean = false;
  private forms: any = {
    ...defaultForms
  };
  private images: any = [];
  private max_upload_image_count:number = 1
  private commonApi: any = new CommonApi();
  private rules = {
    name: [
      {required: true, message: '请输入商品名称', trigger: 'change'},
      {min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'change'}
    ],
    images:[
      {required: false, message: '图片必传', trigger: 'change'},
    ],
    inventory: [
      {validator: this.validateInventory, trigger: 'change'}
    ],
    inventory_warn_num: [
      {validator: this.validateInventoryWarnNum, trigger: 'change'}
    ],
  }

  private validateInventory(rule, value, callback) {
    if (!value || value < 1) {
      return callback(new Error('库存数量最少1'));
    } else if (value > 9999999) {
      callback(new Error('库存数量最多9999999'));
    } else {
      callback();
    }
  }

  private validateInventoryWarnNum(rule, value, callback) {
    if (Number(value) > (this.forms.inventory)) {
      callback(new Error('不能大于库存量'));
    } else {
      callback();
    }
  }

  private form: any = {
    goods_name: "",
  };

  private activated(): void {
    this.getTable();
  }

  private getTable(): void {
    this.buyAddressApi.goodslists(
        {
          ...this.form,
          page: this.tablePage.pageNo,
          limit: this.tablePage.pageSize,
        },
        (res: any) => {
          this.tableData = res.data.list;
          this.tablePage.totalCount = res.data.count;
          this.tablePage.pageNo = res.data.page;
          this.tablePage.prepage = res.data.prepage;
        }
    );
  }

  private restForm(): void {
    this.form = new Util().clearObject(this.form);

    this.getTable();
  }

  private restForms(): void {
    this.forms = new Util().clearObject(this.forms);
  }

  private search(): void {
    this.tablePage.pageNo = 1;
    this.tableData = [];
    this.tablePage.totalCount = 0;

    this.getTable();
  }

  private category: any = [];

  private goodscategory(): void {
    this.buyAddressApi.goodscategory({}, (res: any) => {
      this.category = res.data.list;
    });
  }

  private title: string = "";

  private setGoods(type: string, row: any): void {
    this.restForms();
    this.goodscategory();
    if (type === 'add') {
      this.title = "添加商品";
      this.forms = {
        ...defaultForms
      }
      this.$set(this.forms,'images',[])
    } else {
      this.title = "编辑商品";
      this.forms = row;
      this.forms.images = row.image_id ? [createPicItem(row.image_id, row.image_url)] : [];
    }
    this.sellShow = true;
  }

  private submit(): void {
    this.$refs['form'].validate((valid) => {
      const params = {...this.forms}
      if(params.images.length){
        params.image_id = params.images[0].id
        delete params.images
      }else{
        params.image_id = ''
      }
      if (valid) {
        if (this.title == "添加商品") {
          this.buyAddressApi.goodsadd(params, () => {
            this.sellShow = false;
            Util.showToast("新增成功");
            this.search();
          });
        } else {
          this.buyAddressApi.goodssave(params, () => {
            this.sellShow = false;
            Util.showToast("修改成功");
            this.search();
          });
        }
      } else {
        console.log('error submit!!');
        return false;
      }
    });

  }

  private goodsdelete(id: string, name: string): void {
    this.$confirm(`是否删除${name}?`, "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    })
        .then(() => {
          this.buyAddressApi.goodsdelete(
              {
                id: id,
              },
              () => {
                Util.showToast("删除成功");
                this.search();
              }
          );
          // this.$message({
          //   type: "success",
          //   message: "删除成功!",
          // });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
  }

  private tableData: any[] = [];

  private tablePage: any = {
    totalCount: 0,
    pageNo: 1,
    pageSize: 10,
    pageSizes: [10, 20, 50, 100, 200, 500],
  };

  private highlightRow({row}) {
    if (row.is_highlight === 1) {
      return {'background-color': '#faaeb7'}
    }
  }


  // 预览媒体文件
  private previewImages: any[] = [];
  private handlePictureCardPreview(file: any, mode: string) {
    const forms = this.forms;

    const images = mode == "image" ? forms.images : (forms.media_type === 2 ? forms.videos : []);

    const videos = mode == "video" && forms.media_type === 1 ? forms.videos : [];


    const urls = [
      ...images.map((img: any) => {
        return {
          title: "",
          type: "image/jpeg",
          href: img.url,
          thumbnail: img,
        };
      })
    ];

    console.log(urls, 'urls');

    this.previewImages = urls;
  }


  private handleInputPrice(e: any) {
    this.forms.price = (e.target.value.match(/^\d*(\.?\d{0,1})/g)[0]) || null
  }

  private handleInputWholesalePrice(e: any) {
    this.forms.wholesale_price = (e.target.value.match(/^\d*(\.?\d{0,1})/g)[0]) || null
    if (this.forms.wholesale_price > 99999) {
      this.forms.wholesale_price = 99999
    } else if (this.forms.wholesale_price < 0) {
      this.forms.wholesale_price = 0
    }
  }

  private onInputWholesalePrice(value: any) {
    this.forms.wholesale_price = value.replace(/[^0-9.]/g, '')
    if (this.forms.wholesale_price > 99999) {
      this.forms.wholesale_price = 99999
    } else if (this.forms.wholesale_price < 0) {
      this.forms.wholesale_price = 0
    }
  }

  private handlePageChange(val: any) {
    this.tablePage.pageNo = val.currentPage;
    this.tablePage.pageSize = val.pageSize;

    this.getTable();
  }
  private handleChangeUploadPic(file: any, fileList: any[]) {
    console.log(file, fileList, "file", "fileList");

    this.commonApi.getOss(
        file instanceof File ? file : file.raw,
        (res: any) => {
          console.log(res, "res 图片上传成功");

          const { data } = res;

          const { data: uploadImgObjWrap } = data;

          const { item: uploadImgObj } = uploadImgObjWrap;

          const { id, url } = uploadImgObj;
          //
          // this.forms.images.push(createPicItem(id, url));
          this.$delete(this.forms,'images')
          this.$set(this.forms,'images',[createPicItem(id, url)])
        },
        () => {
          this.$message.error("图片上传失败");
        }
    );
  }

  // 删除媒体文件
  private handleFileRemove(file: any, mode: string) {
    this.$delete(this.forms, 'images');
    this.$set(this.forms, 'images', []);
  }
  private handleBeforePreviewPicture(row: any) {
    const forms = this.forms

    const {
      images
    } = forms || {}

    const previewItem: any = {
      images: images.map((pic: any) => pic.url),
      image_count: images.length
    }

    this.handlePreviewMedia(previewItem)
  }

  private handlePreviewMedia(row: any) {
    const { image_count, images } = row || {};

    const urls: any[] = [];

    if (image_count > 0) {
      urls.push(
          ...images.map((img: string) => {
            return {
              title: "",
              type: "image/jpeg",
              href: img,
              thumbnail: img,
            };
          })
      );
    }


    this.images = urls;
  }
}
